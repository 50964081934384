var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      style: {
        backgroundImage: "url(bg-home-up.png)",
        backgroundOpacity: "0.9",
        width: "100vw"
      },
      attrs: { "fill-height": "", fluid: "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { "align-center": "", "justify-center": "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", sm10: "", md10: "", "full-width": "" } },
            [
              _c(
                "v-card",
                { staticClass: "elevation-3" },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { dark: "", color: "primary" } },
                    [
                      _c(
                        "v-toolbar-title",
                        [
                          _c("v-icon", { staticClass: "ma-2" }, [
                            _vm._v("mdi-account-switch")
                          ]),
                          _vm._v("Selecione um novo Apresentante")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c("v-card-text", [
                        _vm._v(
                          ' Selecione abaixo o apresentante disponível. Assim que o novo apresentante for confirmado clique em "Trocar Apresentante". Os dados do novo apresentante serão carregados no painel. '
                        )
                      ]),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "ml-4", attrs: { md: "6" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.filtros,
                                  label: "Filtros",
                                  "item-text": "name",
                                  "item-value": "value"
                                },
                                on: { change: _vm.listarApresentantes },
                                model: {
                                  value: _vm.filterSelected,
                                  callback: function($$v) {
                                    _vm.filterSelected = $$v
                                  },
                                  expression: "filterSelected"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.salvarNovoApresentante.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "mx-auto",
                                  attrs: {
                                    cols: "12",
                                    sm: "12",
                                    md: "12",
                                    lg: "2",
                                    xl: "2"
                                  }
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c("v-icon", [
                                        _vm._v("mdi-chevron-right")
                                      ]),
                                      _vm._v(" Selecione um apresentante:")
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    sm: "12",
                                    lg: "6",
                                    xl: "6"
                                  }
                                },
                                [
                                  _c("v-card-text", [
                                    _vm._v(" Nome do Apresentante: ")
                                  ]),
                                  _c("v-autocomplete", {
                                    attrs: {
                                      solo: "",
                                      small: "",
                                      label: "Selecione aqui",
                                      "prepend-icon": "mdi-chevron-down",
                                      items: _vm.apresentantes,
                                      "item-value": "cd_apresentante",
                                      "item-text": "nm_apresentante",
                                      loading: _vm.loading
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "item",
                                        fn: function(data) {
                                          return [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _c(
                                                    "span",
                                                    {
                                                      class: {
                                                        "text-gray": !data.item
                                                          .st_ativo
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          data.item
                                                            .nm_apresentante
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c("br"),
                                                  _c("div", [
                                                    _c("small", [
                                                      _vm._v("Remessa: ")
                                                    ]),
                                                    _c(
                                                      "small",
                                                      {
                                                        class: data.item
                                                          .st_ativo_remessa
                                                          ? "text-green"
                                                          : "text-error"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            !!data.item
                                                              .st_ativo_remessa
                                                              ? "Ativo"
                                                              : "Inativo"
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _c("small", [
                                                      _vm._v(" | Comando: ")
                                                    ]),
                                                    _c(
                                                      "small",
                                                      {
                                                        class: !!data.item
                                                          .st_ativo_comando
                                                          ? "text-green"
                                                          : "text-error"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            !!data.item
                                                              .st_ativo_comando
                                                              ? "Ativo"
                                                              : "Inativo"
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ])
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ]),
                                    model: {
                                      value: _vm.apresentanteSelecionado,
                                      callback: function($$v) {
                                        _vm.apresentanteSelecionado = $$v
                                      },
                                      expression: "apresentanteSelecionado"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    sm: "12",
                                    lg: "3",
                                    xl: "3"
                                  }
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    { staticClass: "text-left" },
                                    [_vm._v(" Sigla: ")]
                                  ),
                                  _c("v-select", {
                                    attrs: {
                                      solo: "",
                                      small: "",
                                      "prepend-icon": "mdi-chevron-down",
                                      label: "Selecione",
                                      items: _vm.apresentantesOrderByCd,
                                      "item-value": "cd_apresentante",
                                      "item-text": "cd_apresentante",
                                      loading: _vm.loading
                                    },
                                    model: {
                                      value: _vm.apresentanteSelecionado,
                                      callback: function($$v) {
                                        _vm.apresentanteSelecionado = $$v
                                      },
                                      expression: "apresentanteSelecionado"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("v-col", { attrs: { lg: "1", xl: "1" } })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            "data-cy": "signinSubmitBtn"
                          },
                          on: { click: _vm.salvarNovoApresentante }
                        },
                        [
                          _vm._v(" Trocar Apresentante "),
                          _c("v-icon", { staticClass: "ml-1" }, [
                            _vm._v("chevron_right")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }